import React from 'react';
import PropTypes from 'prop-types';
import 'scss/Modal.scss';

import Button from 'components/Button';

const propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

const Modal = props => {
  return (
    <div className='modal'>
      {props.title && <h1 className='modalTitle'>{props.title}</h1>}
      {props.children}
      <Button text='Done' onClick={props.onClose} />
    </div>
  );
};

Modal.propTypes = propTypes;
export default Modal;
