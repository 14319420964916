import React from 'react';
import PropTypes from 'prop-types';
import 'scss/Toggle.scss';

const propTypes = {
  value: PropTypes.bool,
  label: PropTypes.string,
  onToggle: PropTypes.func.isRequired
};

const Toggle = props => {
  const {
    label,
    onToggle,
    value
  } = props;

  const classes = value ? 'toggleContainer on' : 'toggleContainer';
  return (
    <div className='toggle'>
      {label && <h4 className='toggleLabel'>{label}</h4>}
      <div className={classes} onClick={onToggle}>
        <div className='switch' />
      </div>
    </div>
  );
};

Toggle.propTypes = propTypes;
export default Toggle;
