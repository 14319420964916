import React from 'react';
import 'scss/IslandPage.scss';

import Island from 'components/Island';

const NotFound = () => {
  return (
    <div className='notFound islandPage'>
      <Island>
        <h1>Page Not Found</h1>
      </Island>
    </div>
  );
};

export default NotFound;
