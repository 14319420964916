import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'scss/VAModules.scss';

import Button from 'components/Button';

import useKeyHeld from 'hooks/useKeyHeld';
import useKeyPressed from 'hooks/useKeyPressed';
import { deepCopy } from 'utilities';

const propTypes = {
  vaModules: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    zone: PropTypes.object,
    inZone: PropTypes.object,
    outZone: PropTypes.object
  })),
  hiddenVAModuleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  resolution: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }).isRequired,
  cameraId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

const VAModules = props => {
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [lastMousePosition, setLastMousePosition] = useState([0, 0]);
  const [newVAModule, setNewVAModule] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [pointToResize, setPointToResize] = useState(false);
  const ref = useRef(null);
  const shiftHeld = useKeyHeld('Shift');

  const onKeyPressed = useCallback(event => {
    if (event.key === 'Escape') setNewVAModule([]);
    if (event.key === 'Backspace') {
      if (selectedPolygons.length === 0) return;
      const indices = selectedPolygons.map(key => getIndexFromKey(key));
      setSelectedPolygons([]);
      props.onDelete(indices);
    }
  }, [selectedPolygons, props.onDelete]);
  useKeyPressed(onKeyPressed);

  const getKeyForPolygon = (vaModuleIndex, zoneType, polygonIndex) => `${vaModuleIndex}:${zoneType}:${polygonIndex}`;
  const getIndexFromKey = key => parseInt(key.split(':')[0], 10);

  const getMousePosition = event => {
    const ctm = ref.current.getScreenCTM();
    const newMousePosition = [(event.clientX - ctm.e) / ctm.a, (event.clientY - ctm.f) / ctm.d];
    setLastMousePosition(newMousePosition);
    return newMousePosition;
  };

  const onStartDrag = (event, vaModuleIndex, zoneType, polygonIndex) => {
    event.stopPropagation();
    event.preventDefault();

    const key = getKeyForPolygon(vaModuleIndex, zoneType, polygonIndex);
    if (shiftHeld && !selectedPolygons.includes(key)) setSelectedPolygons([...selectedPolygons, key]);
    else if (shiftHeld && selectedPolygons.includes(key)) setSelectedPolygons(selectedPolygons.filter(polygon => polygon !== key));
    else if (!selectedPolygons.includes(key)) setSelectedPolygons([key]);

    setIsDragging(true);
  };

  const onStartResize = (event, vaModuleIndex, zoneType, polygonIndex, pointIndex) => {
    event.stopPropagation();
    event.preventDefault();
    setPointToResize({
      vaModuleIndex,
      zoneType,
      polygonIndex,
      pointIndex
    });
  };

  const onMouseMove = event => {
    event.stopPropagation();
    event.preventDefault();
    const mousePosition = getMousePosition(event);

    if (isDragging) {
      const dx = mousePosition[0] - lastMousePosition[0];
      const dy = mousePosition[1] - lastMousePosition[1];
      const newVAModules = deepCopy(props.vaModules); // make a copy
      newVAModules.forEach((vaModule, index) => { // for each va module
        ['zone', 'inZone', 'outZone'].forEach(zoneType => { // for each zone type
          if (!vaModule[zoneType]) return;
          vaModule[zoneType].includePolygons.forEach((polygon, polygonIndex) => { // for each selected polygon translate each point by dx, dy
            if (!selectedPolygons.includes(getKeyForPolygon(index, zoneType, polygonIndex))) return;
            vaModule[zoneType].includePolygons = vaModule[zoneType].includePolygons.map(polygon => polygon.map(point => [point[0] + dx, point[1] + dy]));
          });
        });
      });
      props.onUpdate(newVAModules);
    }

    if (pointToResize) {
      const { vaModuleIndex, zoneType, polygonIndex, pointIndex } = pointToResize;
      const newVAModules = deepCopy(props.vaModules); // make a copy
      let newPosition = mousePosition;
      if (zoneType === 'inZone' || zoneType === 'outZone') { // if it is a crossing we'll snap points together
        const oppositeZoneType = zoneType === 'inZone' ? 'outZone' : 'inZone';
        const oppositeZone = newVAModules[vaModuleIndex][oppositeZoneType].includePolygons[polygonIndex];
        let shortestDistance = Number.MAX_VALUE;
        for (let i = 1; i <= oppositeZone.length; i++) {
          /*
                        (mx,my)                        (mx,my)
                        /|                              | \
                  P   /  |                              |   \ p
                    /    |                              |     \
                  |-----------l---->        or          |      |-------l-------->
                (x0,y0)         t (x1,y1)                    (x0,y0)          (x1,y1)
                  --proj->                              <-proj--

            We compute the closest point on the segment by finding the orthogonal projection proj of p onto l where
              l is the segment defined by endpoints (x0,y0) , (x1,y1) ie L=(x1-x0,y1-y0)
              p is defined by (mx-x0, my-y0)  for mouse point (mx,my).
            Then proj=(x0,y0)+t*l for multiplier t=dot(p,l) /||l||.
            If 0<=t<=1, then the closest point on the segment is given by (x0,y0)+t*l.
            Else, then the projection extends beyond the segment and
            If t<0, then the closest point is the first endpoint (x0,y0).
            If t>1, then the closest point is the second endpoint (x1,y1).
          */
          const [[x0, y0], [x1, y1]] = [oppositeZone[i - 1], oppositeZone[i % oppositeZone.length]];
          const [mx, my] = mousePosition;
          const [px, py] = [mx - x0, my - y0];
          const [lx, ly] = [x1 - x0, y1 - y0];
          const dot = px * lx + py * ly;
          const lSq = lx * lx + ly * ly;
          if (lSq > 0) {
            const t = dot / lSq;
            let closestPoint;
            if (t < 0) closestPoint = [x0, y0]; // closest point is beginning of line segment
            else if (t > 1) closestPoint = [x1, y1]; // closest point is end of line
            else closestPoint = [x0 + (t * lx), y0 + (t * ly)]; // closest point is inside line

            const distanceToClosestPoint = Math.sqrt(Math.pow(mx - closestPoint[0], 2) + Math.pow(my - closestPoint[1], 2));
            if (distanceToClosestPoint < shortestDistance && distanceToClosestPoint < props.resolution.width / 100) {
              newPosition = closestPoint;
              shortestDistance = distanceToClosestPoint;
            }
          }
        }
      }
      newVAModules[vaModuleIndex][zoneType].includePolygons[polygonIndex][pointIndex] = newPosition;
      props.onUpdate(newVAModules);
    }
  };

  const onMouseUp = event => {
    event.stopPropagation();
    event.preventDefault();
    setIsDragging(false);
    setPointToResize(null);
  };

  const onInsertPoint = () => {
    if (selectedPolygons.length > 0) return setSelectedPolygons([]);
    setNewVAModule([...newVAModule, lastMousePosition]);
  };

  const onCompleteVAModule = event => {
    event.preventDefault();
    event.stopPropagation();
    if (newVAModule.length > 2) {
      const newVAModuleIndex = props.vaModules.length;
      props.onUpdate([...props.vaModules, {
        name: `Module ${props.vaModules.length}`,
        type: 'Counting',
        cameraId: props.cameraId,
        zone: { includePolygons: [newVAModule], excludePolygons: [] }
      }]);
      setSelectedPolygons([getKeyForPolygon(newVAModuleIndex, 'zone', 0)]);
    }
    setNewVAModule([]);
  };

  const onRemovePoint = (event, vaModuleIndex, zoneType, polygonIndex, pointIndex) => {
    event.stopPropagation();
    event.preventDefault();
    const newVAModules = deepCopy(props.vaModules);
    if (newVAModules[vaModuleIndex][zoneType].includePolygons[polygonIndex].length <= 3) return;
    newVAModules[vaModuleIndex][zoneType].includePolygons[polygonIndex].splice(pointIndex, 1);
    props.onUpdate(newVAModules);
  };

  const mergeVAModules = () => {
    const newVAModules = deepCopy(props.vaModules);
    const i1 = getIndexFromKey(selectedPolygons[0]);
    const i2 = getIndexFromKey(selectedPolygons[1]);
    if (i1 === i2 || newVAModules[i1].type === 'Crossing' || newVAModules[i2].type === 'Crossing') return console.error('Polygons already joined');
    props.onDelete([i1, i2]);
    newVAModules[i1].inZone = newVAModules[i1].zone;
    newVAModules[i1].outZone = newVAModules[i2].zone;
    delete newVAModules[i1].zone;
    delete newVAModules[i1].id;
    newVAModules[i1].type = 'Crossing';
    newVAModules.splice(i2, 1);
    setSelectedPolygons([]);
    props.onUpdate(newVAModules);
  };

  const renderPolygon = (vaModuleIndex, zoneType, polygonIndex) => {
    const polygon = props.vaModules[vaModuleIndex][zoneType].includePolygons[polygonIndex];
    const key = getKeyForPolygon(vaModuleIndex, zoneType, polygonIndex);
    const isSelected = selectedPolygons.includes(key);

    // find bounds
    let min_x = Number.MAX_VALUE, min_y = Number.MAX_VALUE, max_x = 0, max_y = 0;
    polygon.forEach(point => {
      if (point[0] < min_x) min_x = point[0];
      if (point[0] > max_x) max_x = point[0];
      if (point[1] < min_y) min_y = point[1];
      if (point[1] > max_y) max_y = point[1];
    });

    return (
      <g key={key} className={`polygonContainer ${isSelected ? 'selected' : ''} ${isDragging ? 'dragging' : ''} ${newVAModule.length > 0 ? 'disabled' : ''}`}>
        <polygon
          points={polygon.map(point => point.join(',')).join(' ')} style={{ strokeWidth: props.resolution.width / 560 }}
          onMouseDown={event => onStartDrag(event, vaModuleIndex, zoneType, polygonIndex)} />
        <text x={(max_x + min_x) / 2} y={(max_y + min_y) / 2} textAnchor='middle'>{props.vaModules[vaModuleIndex].name}</text>
        {isSelected && polygon.map((point, pointIndex) => (
          <g key={pointIndex}>
            <circle // visible circle
              className='handle'
              cx={point[0]}
              cy={point[1]}
              r={props.resolution.width / 280}/>
            <circle // click target
              className='handleTarget'
              cx={point[0]}
              cy={point[1]}
              r={props.resolution.width / 140}
              onMouseDown={event => onStartResize(event, vaModuleIndex, zoneType, polygonIndex, pointIndex)}
              onContextMenu={event => onRemovePoint(event, vaModuleIndex, zoneType, polygonIndex, pointIndex)} />
          </g>
        ))}
      </g>
    );
  };

  const renderVAModule = (vaModule, index) => {
    if (!vaModule) return null;
    if (props.hiddenVAModuleIndices.includes(index)) return null;
    return (
      <g className='vaModule' key={vaModule.id || index}>
        { /* unselected polygons */ }
        {vaModule.zone && <g className='zone'>{vaModule.zone.includePolygons.map((_, polygonIndex) => selectedPolygons.includes(getKeyForPolygon(index, 'zone', polygonIndex)) ? null : renderPolygon(index, 'zone', polygonIndex))}</g>}
        {vaModule.inZone && <g className='inZone'>{vaModule.inZone.includePolygons.map((_, polygonIndex) => selectedPolygons.includes(getKeyForPolygon(index, 'inZone', polygonIndex)) ? null : renderPolygon(index, 'inZone', polygonIndex))}</g>}
        {vaModule.outZone && <g className='outZone'>{vaModule.outZone.includePolygons.map((_, polygonIndex) => selectedPolygons.includes(getKeyForPolygon(index, 'outZone', polygonIndex)) ? null : renderPolygon(index, 'outZone', polygonIndex))}</g>}
        {selectedPolygons.map(key => { // render selected polygons last so they show up on top
          const [vaModuleIndex, zoneType, polygonIndex] = key.split(':');
          if (parseInt(vaModuleIndex, 10) !== index) return null; // only render selected polys for this module
          return <g className='selected' key={key}>{renderPolygon(vaModuleIndex, zoneType, polygonIndex)}</g>;
        })}
      </g>
    );
  };

  const selectedIndices = selectedPolygons.map(key => getIndexFromKey(key));

  return (
    <div className='vaModules'>
      <svg
        viewBox={`0 0 ${props.resolution.width} ${props.resolution.height}`}
        onMouseDown={onInsertPoint}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseUp}
        onBlur={onMouseUp}
        ref={ref}>
        {/* render va modules with selected polygons at the end to make sure they're always on top */}
        {props.vaModules.map((vaModule, index) => selectedIndices.includes(index) ? null : renderVAModule(vaModule, index))}
        {selectedIndices.map(index => renderVAModule(props.vaModules[index], index))}
        {newVAModule.map((point, index) => (
          <g key={index}>
            <circle // visible
              cx={point[0]}
              cy={point[1]}
              r={props.resolution.width / 280}
              className='handle' />
            <circle // click target
              cx={point[0]}
              cy={point[1]}
              r={props.resolution.width / 140}
              className='handleTarget'
              onMouseDown={onCompleteVAModule} />
            <line
              x1={point[0]}
              y1={point[1]}
              x2={newVAModule[index + 1] ? newVAModule[index + 1][0] : lastMousePosition[0]}
              y2={newVAModule[index + 1] ? newVAModule[index + 1][1] : lastMousePosition[1]}
              strokeWidth={props.resolution.width / 560}
              stroke='white' />
          </g>
        ))}
      </svg>
      {selectedPolygons.length === 2 && <Button text='join' size='small' onClick={mergeVAModules} />}
    </div>
  );
};

VAModules.propTypes = propTypes;
export default VAModules;
