import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import 'scss/ConfigurePage.scss';
import 'scss/IslandPage.scss';

import Button from 'components/Button';
import FileUpload from 'components/FileUpload';
import Image from 'components/Image';
import InputWithLabel from 'components/InputWithLabel';
import Island from 'components/Island';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';

import { TIME_ZONES, getUTCHourOffset, dateToString } from 'util/timeZone';

const ConfigureSite = () => {
  const [success, setSuccess] = useState(null);
  const [showInferCommands, setShowInferCommands] = useState(false);
  const [siteName, setSiteName] = useState(null);
  const [siteAddress, setSiteAddress] = useState(null);
  const [siteTimeZone, setSiteTimeZone] = useState(null);
  const [newSiteLogo, setNewSiteLogo] = useState(null);
  const [creationMode, setCreationMode] = useState(null);
  const [newCameraName, setNewCameraName] = useState('');
  const [newCameraUrl, setNewCameraUrl] = useState('');
  const [newCameraIsFisheye, setNewCameraIsFisheye] = useState(false);
  const [newCameraFrame, setNewCameraFrame] = useState(null);
  const [newGraphTitle, setNewGraphTitle] = useState('');
  const [newGraphDescription, setNewGraphDescription] = useState('');
  const [newGraphYAxisLabel, setNewGraphYAxisLabel] = useState('');
  const [newGraphImage, setNewGraphImage] = useState(null);
  const [newGraphAlgorithm, setNewGraphAlgorithm] = useState('');
  const [newGraphObjectType, setNewGraphObjectType] = useState('');
  const [newHeatMapTitle, setNewHeatMapTitle] = useState('');
  const [newHeatMapDescription, setNewHeatMapDescription] = useState('');
  const [newHeatMapFloorPlan, setNewHeatMapFloorPlan] = useState(null);

  const { loading, refetch, data: { site } = {} } = useQuery(GET_SITE);

  const [createCamera, { loading: creatingCamera }] = useMutation(CREATE_CAMERA, {
    onCompleted: () => {
      setCreationMode('');
      setNewCameraName('');
      setNewCameraUrl('');
      setNewCameraIsFisheye(false);
      setNewCameraFrame(null);
      refetch();
    },
    variables: {
      siteId: site ? site.id : '',
      name: newCameraName || null, // default to null because graphql accepts empty strings
      url: newCameraUrl || null, // default to null because graphql accepts empty strings
      isFisheye: newCameraIsFisheye,
      file: newCameraFrame
    }
  });

  const [createGraph, { loading: creatingGraph }] = useMutation(CREATE_GRAPH, {
    onCompleted: () => {
      setCreationMode('');
      setNewGraphTitle('');
      setNewGraphDescription('');
      setNewGraphYAxisLabel('');
      setNewGraphAlgorithm('');
      setNewGraphObjectType('');
      setNewGraphImage(null);
      refetch();
    },
    variables: {
      siteId: site ? site.id : '',
      title: newGraphTitle || null,
      description: newGraphDescription || null, // default to null because graphql accepts empty strings
      yAxisLabel: newGraphYAxisLabel || null, // default to null because graphql accepts empty strings
      algorithm: newGraphAlgorithm || null, // default to null because graphql accepts empty strings
      objectType: newGraphObjectType || null, // default to null because graphql accepts empty strings
      file: newGraphImage
    }
  });

  const [updateSite, { loading: updatingSite }] = useMutation(UPDATE_SITE, {
    onCompleted: () => {
      refetch();
      setSiteName(null);
      setSiteAddress(null);
      setSiteTimeZone(null);
      setNewSiteLogo(null);
      setSuccess('Site updated successfully!');
    },
    variables: {
      id: site ? site.id : '',
      name: siteName,
      address: siteAddress,
      timeZone: siteTimeZone,
      file: newSiteLogo
    }
  });

  const [createHeatMap, { loading: creatingHeatMap }] = useMutation(CREATE_HEAT_MAP, {
    onCompleted: () => {
      setCreationMode('');
      setNewHeatMapTitle('');
      setNewHeatMapDescription('');
      setNewHeatMapFloorPlan('');
      refetch();
    },
    variables: {
      siteId: site ? site.id : '',
      title: newHeatMapTitle || null, // default to null because graphql accepts empty strings
      description: newHeatMapDescription || null, // default to null because graphql accepts empty strings
      file: newHeatMapFloorPlan
    }
  });

  const [deleteCamera, { loading: isDeletingCamera }] = useMutation(DELETE_CAMERA, { onCompleted: refetch });
  const [deleteGraph, { loading: isDeletingGraph }] = useMutation(DELETE_GRAPH, { onCompleted: refetch });
  const [deleteHeatMap, { loading: isDeletingHeatMap }] = useMutation(DELETE_HEAT_MAP, { onCompleted: refetch });
  const [saveConfiguration, { loading: savingConfiguration }] = useMutation(SAVE_CONFIGURATION, { onCompleted: () => {
    refetch();
    setSuccess('Successfully updated configuration.');
  } });

  const onCreate = () => {
    if (creationMode === 'camera') return creatingCamera ? () => {} : createCamera();
    if (creationMode === 'graph') return creatingGraph ? () => {} : createGraph();
    if (creationMode === 'heatMap') return creatingHeatMap ? () => {} : createHeatMap();
  };

  const onDeleteCamera = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    if (isDeletingCamera) return;
    deleteCamera({ variables: { id } });
  };

  return (
    <div className='configureSite configurePage islandPage'>
      <Island>
        {loading && <div className='loading'><Spinner /></div>}

        {(!loading && site && !creationMode) && <div className='configureContents'>
          <div className='details'>
            <div className='detailsTop'>
              <Link to={'/configure'}>&lsaquo;</Link>
              <h1>Site Details</h1>
            </div>
            <InputWithLabel label='Site Name' value={siteName === null ? site.name : siteName} onChange={event => setSiteName(event.target.value)} />
            <InputWithLabel label='Site Address' value={siteAddress === null ? site.address : siteAddress} onChange={event => setSiteAddress(event.target.value)} />
            <select value={siteTimeZone === null ? (site.timeZone || '') : siteTimeZone} onChange={event => setSiteTimeZone(event.target.value)}>
              <option value='' disabled>Time Zone</option>
              {TIME_ZONES.map(timeZone => <option key={timeZone} value={timeZone}>UTC {getUTCHourOffset(timeZone) >= 0 ? '+' : ''}{getUTCHourOffset(timeZone)} ({timeZone.replace('_', ' ')})</option>)}
            </select>
            <FileUpload file={newSiteLogo} buttonText='Change Logo' onChange={file => setNewSiteLogo(file)} />
            <Button text={updatingSite ? 'Updating...' : 'Update Site Details'} onClick={updateSite} />
          </div>
          <div className='settings'>
            <h1>Site Contents</h1>
            <div className='section'>
              <div className='sectionTitle'>Cameras</div>
              <div className='imageGrid'>
                {site.cameras.map(camera => <Link to={`/configure-camera/${camera.id}`} className='camera gridItem' key={camera.id}>
                  <Image src={`data:image/jpeg;base64, ${camera.image.data}`} alt='camera frame' />
                  <div className='name'>{camera.name}</div>
                  <div className='delete' onClick={event => onDeleteCamera(event, camera.id)}>&times;</div>
                </Link>)}
                <div className='add' onClick={() => setCreationMode('camera')}><div className='plus'>+</div></div>
              </div>
            </div>
            <div className='section'>
              <div className='sectionTitle'>Graphs</div>
              {site.graphs.map(graph => <div className='sectionRow graph' key={graph.id}>
                <Link to={`/configure-graph/${graph.id}`} className='name'>{graph.title}</Link>
                <div className='delete' onClick={isDeletingGraph ? () => {} : () => deleteGraph({ variables: { id: graph.id } })}>&times;</div>
              </div>)}
              <div className='fakeLink' onClick={() => setCreationMode('graph')}>+ add graph</div>
            </div>
            <div className='section'>
              <div className='sectionTitle'>Heat Maps</div>
              {site.heatMaps.map(heatMap => <div className='sectionRow heatMap' key={heatMap.id}>
                <Link to={`/configure-heat-map/${heatMap.id}`} className='name'>{heatMap.title}</Link>
                <div className='delete' onClick={isDeletingHeatMap ? () => {} : () => deleteHeatMap({ variables: { id: heatMap.id } })}>&times;</div>
              </div>)}
              <div className='fakeLink' onClick={() => setCreationMode('heatMap')}>+ add heat map</div>
            </div>
            <Button text={savingConfiguration ? 'Deploying...'  : 'Deploy Configuration'} onClick={savingConfiguration ? () => {} : saveConfiguration} />
            {!site.config && <div className='noConfig'>No configuration found. Click above to generate.</div>}
            {site.config && <div className='configuration'>
              <span className='lastGenerated'>Configuration last generated {dateToString(new Date(site.config.generatedAt))}. </span>
              <span className='fakeLink' onClick={() => setShowInferCommands(true)}>View Infer Commands</span>
            </div>}
          </div>
        </div>}

        {(!loading && site && creationMode) && <div className='creation'>
          <div className='form'>
            {creationMode === 'camera' && <div>
              <h1>Add Camera</h1>
              <InputWithLabel label='Name' value={newCameraName} onChange={event => setNewCameraName(event.target.value)} />
              <InputWithLabel label='RTSP Url' value={newCameraUrl} onChange={event => setNewCameraUrl(event.target.value)} />
              <Toggle label='Is Fisheye' value={newCameraIsFisheye} onToggle={() => setNewCameraIsFisheye(!newCameraIsFisheye)} />
              <FileUpload file={newCameraFrame} buttonText='Add Frame' onChange={file => setNewCameraFrame(file)} />
            </div>}
            {creationMode === 'graph' && <div>
              <h1>Add Graph</h1>
              <InputWithLabel label='Title' value={newGraphTitle} onChange={event => setNewGraphTitle(event.target.value)} />
              <InputWithLabel label='Description' value={newGraphDescription} onChange={event => setNewGraphDescription(event.target.value)} />
              <InputWithLabel label='Y Axis Label' value={newGraphYAxisLabel} onChange={event => setNewGraphYAxisLabel(event.target.value)} />
              <div className='row'>
                <select value={newGraphAlgorithm} onChange={event => setNewGraphAlgorithm(event.target.value)}>
                  <option value='' disabled>Algorithm</option>
                  <option value='eventCount'>Event Count</option>
                  <option value='eventCountAverage'>Event Count Average</option>
                  <option value='eventValueAverage'>Event Value Average</option>
                  <option value='anyEvent'>Any Event</option>
                </select>
                <select value={newGraphObjectType} onChange={event => setNewGraphObjectType(event.target.value)}>
                  <option value='' disabled>Object Type</option>
                  <option value='body'>Body</option>
                  <option value='vehicle'>Vehicle</option>
                  <option value='gas_pump_handle'>Gas Pump Handle</option>
                </select>
              </div>
              <FileUpload file={newGraphImage} buttonText='Add Image' onChange={file => setNewGraphImage(file)} />
            </div>}
            {creationMode === 'heatMap' && <div>
              <h1>Add Heat Map</h1>
              <InputWithLabel label='Title' value={newHeatMapTitle} onChange={event => setNewHeatMapTitle(event.target.value)} />
              <InputWithLabel label='Description' value={newHeatMapDescription} onChange={event => setNewHeatMapDescription(event.target.value)} />
              <FileUpload file={newHeatMapFloorPlan} buttonText='Add Floor Plan' onChange={file => setNewHeatMapFloorPlan(file)} />
            </div>}
            <div className='buttons'>
              <Button text='Cancel' onClick={() => setCreationMode('')} />
              <Button text='Create' onClick={onCreate} />
            </div>
          </div>
        </div>}
      </Island>
      {success && <Modal title='Success!' onClose={() => setSuccess(null)}>{success}</Modal>}
      {showInferCommands && <Modal title='Infer Commands' onClose={() => setShowInferCommands(false)}>
        {(site && site.config && site.config.inferCommands) ? <textarea spellCheck={false} className='inferCommands'>
          {site.config.inferCommands.join('\n\n')}
        </textarea> : <div>No Infer Commands</div>}
      </Modal>}
    </div>
  );
};

const GET_SITE = gql`
  {
    site {
      id
      name
      address
      timeZone
      cameras {
        id
        name
        url
        image {
          data
        }
      }
      graphs {
        id
        title
        description
        image {
          data
        }
        yAxisLabel
        algorithm
      }
      heatMaps {
        id
        title
      }
      config {
        id
        generatedAt
        inferCommands
      }
    }
  }
`;

const UPDATE_SITE = gql`
  mutation updateSite($id: ID!, $name: String, $address: String, $timeZone: String, $file: Upload) {
    updateSite(id: $id, name: $name, address: $address, timeZone: $timeZone, file: $file)
  }
`;

const CREATE_CAMERA = gql`
  mutation createCamera($siteId: ID!, $name: String!, $url: String!, $isFisheye: Boolean!, $file: Upload!) {
    createCamera(siteId: $siteId, name: $name, url: $url, isFisheye: $isFisheye, file: $file) { id }
  }
`;

const CREATE_GRAPH = gql`
  mutation createGraph($siteId: ID!, $title: String!, $description: String!, $yAxisLabel: String!, $algorithm: String!, $objectType: String!, $file: Upload) {
    createGraph(siteId: $siteId, title: $title, description: $description, yAxisLabel: $yAxisLabel, algorithm: $algorithm, objectType: $objectType, file: $file) { id }
  }
`;

const CREATE_HEAT_MAP = gql`
  mutation createHeatMap($siteId: ID!, $title: String!, $description: String!, $file: Upload!) {
    createHeatMap(siteId: $siteId, title: $title, description: $description, file: $file) { id }
  }
`;

const DELETE_CAMERA = gql`
  mutation deleteCamera($id: ID!) {
    deleteCamera(id: $id)
  }
`;

const DELETE_GRAPH = gql`
  mutation deleteGraph($id: ID!) {
    deleteGraph(id: $id)
  }
`;

const DELETE_HEAT_MAP = gql`
  mutation deleteHeatMap($id: ID!) {
    deleteHeatMap(id: $id)
  }
`;

const SAVE_CONFIGURATION = gql`
  mutation saveConfiguration {
    saveConfiguration
  }
`;

export default ConfigureSite;
