import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import 'scss/ConfigurePage.scss';
import 'scss/IslandPage.scss';

import Button from 'components/Button';
import FileUpload from 'components/FileUpload';
import InputWithLabel from 'components/InputWithLabel';
import Island from 'components/Island';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

const ConfigureHeatMap = props => {
  const id = props.match.params.id;

  const [success, setSuccess] = useState(null);
  const [heatMapTitle, setHeatMapTitle] = useState(null);
  const [heatMapDescription, setHeatMapDescription] = useState(null);
  const [heatMapFloorPlan, setHeatMapFloorPlan] = useState(null);

  const { loading, refetch, data: { heatMap, camerasWithHomographies: cameras } = {} } = useQuery(GET_HEAT_MAP, { variables: { id } });

  const [updateHeatMap, { loading: updatingHeatMap }] = useMutation(UPDATE_HEAT_MAP, {
    onCompleted: () => setSuccess('Successfully updated heat map.'),
    variables: {
      id: heatMap ? heatMap.id : '',
      title: heatMapTitle,
      description: heatMapDescription,
      file: heatMapFloorPlan
    }
  });
  const [addCamera, { loading: addingCamera }] = useMutation(ADD_CAMERA, { onCompleted: refetch });
  const [removeCamera, { loading: removingCamera }] = useMutation(REMOVE_CAMERA, { onCompleted: refetch });

  return (
    <div className='configureHeatMap configurePage islandPage'>
      <Island>
        {loading && <div className='loading'><Spinner /></div>}
        {(!loading && heatMap && cameras) && <div className='configureContents'>
          <div className='details'>
            <div className='detailsTop'>
              <Link to={`/configure/${heatMap.siteId}`}>&lsaquo;</Link>
              <h1>Heat Map Details</h1>
            </div>
            <InputWithLabel label='Title' value={heatMapTitle || heatMap.title} onChange={event => setHeatMapTitle(event.target.value)} />
            <InputWithLabel label='Description' value={heatMapDescription || heatMap.description} onChange={event => setHeatMapDescription(event.target.value)} />
            <FileUpload file={heatMapFloorPlan} buttonText='Add Floor Plan' onChange={file => setHeatMapFloorPlan(file)} />
            <Button text={updatingHeatMap ? 'Updating...' : 'Update Heat Map'} onClick={updatingHeatMap ? () => {} : updateHeatMap} />
          </div>
          <div className='settings'>
            <h1>Heat Map Contents</h1>
            <div className='section'>
              <div className='sectionTitle'>Included Cameras</div>
              {heatMap.cameras.map(camera => <div className='sectionRow vaModule' key={camera.id}>
                <div className='name'>{camera.name}</div>
                <div className='delete' onClick={removingCamera ? () => {} : () => removeCamera({ variables: { heatMapId: heatMap.id, cameraId: camera.id } })}>&times;</div>
              </div>)}
              <select value='' onChange={addingCamera ? () => {} : event => addCamera({ variables: { heatMapId: heatMap.id, cameraId: event.target.value } })}>
                <option value='' disabled={true}>Add Camera</option>
                {cameras.map(camera => <option key={camera.id} value={camera.id}>{camera.name}</option>)}
              </select>
            </div>
          </div>
        </div>}
      </Island>
      {success && <Modal title='Success!' onClose={() => setSuccess(null)}>{success}</Modal>}
    </div>
  );
};


const GET_HEAT_MAP = gql`
  query heatMap($id: ID!) {
    heatMap(id: $id) {
      id
      title
      description
      siteId
      cameras {
        id
        name
      }
    }

    camerasWithHomographies {
      id
      name
    }
  }
`;

const UPDATE_HEAT_MAP = gql`
  mutation updateHeatMap($id: ID!, $title: String, $description: String, $file: Upload) {
    updateHeatMap(id: $id, title: $title, description: $description, file: $file)
  }
`;

const ADD_CAMERA = gql`
  mutation addCameraToHeatMap($heatMapId: ID!, $cameraId: ID!) {
    addCameraToHeatMap(heatMapId: $heatMapId, cameraId: $cameraId)
  }
`;

const REMOVE_CAMERA = gql`
  mutation removeCameraFromHeatMap($heatMapId: ID!, $cameraId: ID!) {
    removeCameraFromHeatMap(heatMapId: $heatMapId, cameraId: $cameraId)
  }
`;

export default ConfigureHeatMap;
