import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { ResponsiveLine } from '@nivo/line';
import 'react-datepicker/dist/react-datepicker.css';
import 'scss/DatePicker.scss';
import 'scss/IslandPage.scss';
import 'scss/Graph.scss';

import Event from 'components/Event';
import Image from 'components/Image';
import Island from 'components/Island';
import Spinner from 'components/Spinner';
import Tooltip from 'components/Tooltip';

const TIMES = {
  quarter: {
    minInterval: 60 * 60 * 24 * 30 * 3 * 6 * 1000, // more than 6 quarters
    format: '%b %Y',
    precision: 'month',
    tickValues: 'every 6 months'
  },
  month: {
    minInterval: 60 * 60 * 24 * 30 * 6 * 1000, // more than 6 months
    format: '%b %Y',
    precision: 'month',
    tickValues: 'every 2 months'
  },
  week: {
    minInterval: 60 * 60 * 24 * 7 * 6 * 1000, // more than 6 weeks
    format: '%b %d',
    precision: 'day',
    tickValues: 'every 14 days'
  },
  day: {
    minInterval: 60 * 60 * 24 * 4 * 1000, // more than 4 days
    format: '%b %d',
    precision: 'day',
    tickValues: 'every 2 days'
  },
  hour: {
    minInterval: 0,
    format: '%I%p',
    precision: 'hour',
    tickValues: 'every 2 hours'
  }
};

const EVENTS_PER_PAGE = 6;

const propTypes = {
  id: PropTypes.string.isRequired
};

const Graph = props => {
  const id = props.id;
  const [minTime, setMinTime] = useState(new Date(Date.now() - (24 * 60 * 60 * 1000)));
  const [maxTime, setMaxTime] = useState(new Date());
  const [eventPage, setEventPage] = useState(0);

  let timeScale = 'hour';
  if (maxTime.getTime() - minTime.getTime() > TIMES.quarter.minInterval) timeScale = 'quarter';
  else if (maxTime.getTime() - minTime.getTime() > TIMES.month.minInterval) timeScale = 'month'; // more than 3 months
  else if (maxTime.getTime() - minTime.getTime() > TIMES.week.minInterval) timeScale = 'week'; // more than 3 weeks
  else if (maxTime.getTime() - minTime.getTime() > TIMES.day.minInterval) timeScale = 'day'; // more than 3 days

  const { loading, data: { graph } = {} } = useQuery(GET_GRAPH, {
    variables: {
      id,
      timeScale,
      minTime: minTime.getTime() / 1000,
      maxTime: maxTime.getTime() / 1000,
      eventSkip: eventPage * EVENTS_PER_PAGE,
      eventLimit: EVENTS_PER_PAGE
    }
  });

  const filters = (
    <div className='filters'>
      <DatePicker
        selected={minTime}
        showTimeSelect={true}
        timeIntervals={60}
        dateFormat='MM/dd/yy haa'
        disabledKeyboardNavigation={true}
        onChange={time => setMinTime(time)} />
      <div className='divider'>&mdash;</div>
      <DatePicker
        selected={maxTime}
        showTimeSelect={true}
        timeIntervals={60}
        dateFormat='MM/dd/yy haa'
        disabledKeyboardNavigation={true}
        onChange={time => setMaxTime(time)} />
    </div>
  );

  const events = (
    <Island className='events' hidden={!graph || graph.events.length === 0}>
      <div className='titleContainer'>
        <h1>Events</h1>
      </div>
      {graph && graph.events.map((event, index) => <Event key={event.id} type={event.type} timestamp={event.timestamp} output={event.output} />)};
      <div className='eventPagination'>
        <div className={'prevPage' + (!graph || eventPage === 0 ? ' disabled' : '')} onClick={() => setEventPage(eventPage - 1)}>&larr;</div>
        <div className={'nextPage' + (!graph || graph.events.length < EVENTS_PER_PAGE ? ' disabled' : '')} onClick={() => setEventPage(eventPage + 1)}>&rarr;</div>
      </div>
    </Island>
  );

  if (loading || !graph || !graph.data || graph.data.length === 0) {
    return (
      <div className='graphPage islandPage'>
        <Island>
          <div className='graph'>
            <div className='graphTop'>
              <div className='titleContainer'><h1>{loading ? 'Loading...' : 'No Data Found'}</h1></div>
              {filters}
            </div>
            {loading && <div className='graphContainer loading'><Spinner /></div>}
          </div>
        </Island>
        {events}
      </div>
    );
  }

  const graphData = graph.data.map(({ label, data }) => ({
    id: label,
    data: data.map(({ x, y }) => ({ x: new Date(x * 1000), y }))
  }));

  return (
    <div className='graphPage islandPage'>
      <Island>
        <div className='graph'>
          <div className='graphTop'>
            <div className='titleContainer'>
              <h1>{graph.title}</h1>
              <Tooltip type='info' width={520}>
                <div className='graphTooltip'>
                  {graph.image && <Image src={`data:image/jpeg;base64, ${graph.image.data}`} alt='data source' />}
                  <div className='graphTooltipDescription'>{graph.description}</div>
                </div>
              </Tooltip>
              <div>&nbsp;&nbsp;</div>
            </div>
            {filters}
          </div>
          <div className='graphContainer'>
            <ResponsiveLine
              data={graphData}
              colors={{ scheme: 'category10' }}
              margin={{ top: 40, right: 40, bottom: 60, left: 40 }}
              animate={true}
              enableSlices='x'
              xScale={{
                type: 'time',
                format: 'native',
                useUTC: false,
                precision: TIMES[timeScale].precision
              }}
              yScale={{
                type: 'linear',
                stacked: false
              }}
              axisLeft={{
                legend: graph.yAxisLabel,
                legendOffset: 12
              }}
              axisBottom={{
                format: TIMES[timeScale].format,
                tickValues: TIMES[timeScale].tickValues
              }}
              curve='monotoneX'
              useMesh={true} />
          </div>
          <div className='legend'>
            {graphData.map(({ id }, index) => (
              <div key={id} className='legendEntry'>
                <div className='colorIndicator' style={{ backgroundColor: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c554c'][index] }} />
                <div className='label'>{id}</div>
              </div>
            ))}
          </div>
          <div className='sources'>{graph.sources.length === 1 ? 'source' : 'sources'}: {graph.sources.join(', ')}</div>
        </div>
      </Island>
      {events}
    </div>
  );
};

const GET_GRAPH = gql`
  query graph($id: ID!, $timeScale: String!, $minTime: Float!, $maxTime: Float!, $eventSkip: Int!, $eventLimit: Int!) {
    graph(id: $id) {
      id
      title
      description
      image {
        data
      }
      yAxisLabel
      sources
      data(timeScale: $timeScale, minTime: $minTime, maxTime: $maxTime) {
        label
        data {
          id
          x
          y
        }
      }
      events(minTime: $minTime, maxTime: $maxTime, limit: $eventLimit, skip: $eventSkip) {
        id,
        type,
        timestamp,
        output {
          name,
          value
        }
      }
    }
  }
`;

Graph.propTypes = propTypes;
export default Graph;
