import { useEffect } from 'react';

const useKeyPressed = onKeyPress => { // expects {keyName: handlerFunction}
  useEffect(() => {
    window.addEventListener('keydown', onKeyPress);
    // unmount
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [onKeyPress]);
};

export default useKeyPressed;
