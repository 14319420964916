import React from 'react';
import PropTypes from 'prop-types';
import 'scss/Event.scss';

import { timeToString } from 'utilities';

const propTypes = {
  type: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  output: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  }))
};

const Event = props => {
  return (
    <div className='event'>
      <a href='#' target='_blank' rel='noreferrer'>{props.type}</a>
      <div className='timestamp'>{timeToString(props.timestamp)}</div>
      {props.output.length > 0 && <div className='eventOutput'>
        {props.output.map(({ name, value }, index) => <span className='output' key={name}>{index !== 0 ? ', ' : ''}{value}</span>)}
      </div>}
    </div>
  );
};

Event.propTypes = propTypes;
export default Event;
