import React, { useState, useContext } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import 'scss/Login.scss';

import { AccessTokenContext } from 'context/AccessToken';
import Button from 'components/Button';
import Island from 'components/Island';

const Login = props => {
  const { setAccessToken } = useContext(AccessTokenContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const [onLogin, { loading }] = useMutation(LOGIN_USER, {
    update: (_, { data: { login } }) => {
      setAccessToken(login.token);
      props.history.push('/');
    },
    onError: err => {
      setError(err.message);
    },
    variables: {
      username,
      password
    }
  });

  const onKeyDown = event => {
    if (event.key !== 'Enter') return false;
    event.preventDefault();
    onLogin();
  };

  return (
    <div className='login'>
      <Island style='dark'>
        <div className={'loginForm' + (loading ? ' loading' : '')}>
          <h1>Welcome Back</h1>
          <input
            placeholder='username'
            name='username'
            type='text'
            value={username}
            onChange={event => setUsername(event.target.value)} />
          <input
            placeholder='password'
            name='password'
            type='password'
            value={password}
            onKeyDown={onKeyDown}
            onChange={event => setPassword(event.target.value)} />
          <Button text='Login' size='large' onClick={loading ? () => {} : onLogin} />
        </div>
        {error && <div className='error'>{error}</div>}
      </Island>
    </div>
  );
};

const LOGIN_USER = gql`
  mutation login(
    $username: String!
    $password: String!
  ) {
    login(
      username: $username
      password: $password
    ){
      token
    }
  }
`;

export default Login;
