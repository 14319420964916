import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'scss/Tooltip.scss';

const propTypes = {
  location: PropTypes.oneOf(['left', 'right']),
  type: PropTypes.oneOf(['info', 'configuration']),
  width: PropTypes.number,
  onClick: PropTypes.func
};

const defaultProps = {
  location: 'right',
  type: 'info',
  width: 240
};

const Tooltip = props => {
  const [ open, setOpen ] = useState(false);

  return (
    <div className='tooltip' onClick={props.onClick}>
      <div className={`tooltipIcon ${props.type}`} onMouseEnter={props.onClick ? null : () => setOpen(true)} onMouseLeave={props.onClick ? null : () => setOpen(false)}>
        {props.type === 'info' && <span>?</span>}
        {props.type === 'configuration' && <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#000000' d='M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z'/></svg>}
      </div>
      {props.children && <div className={`tooltipContents ${props.location} ${props.type}` + (open ? ' show' : '')} style={{ width: props.width }}>{props.children}</div>}
    </div>
  );
};

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;
export default Tooltip;
