import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import 'scss/Configure.scss';
import 'scss/IslandPage.scss';

import Button from 'components/Button';
import FileUpload from 'components/FileUpload';
import Spinner from 'components/Spinner';
import InputWithLabel from 'components/InputWithLabel';
import Island from 'components/Island';

import { TIME_ZONES, getUTCHourOffset } from 'util/timeZone';

const Configure = () => {
  const [newSiteName, setNewSiteName] = useState('');
  const [newSiteAddress, setNewSiteAddress] = useState('');
  const [newSiteTimeZone, setNewSiteTimeZone] = useState('');
  const [newSiteLogo, setNewSiteLogo] = useState(null);

  const { loading, data: { sites } = {} } = useQuery(GET_SITES);
  const [createSite, { loading: creating }] = useMutation(CREATE_SITE, {
    onCompleted: () => {
      window.location.reload();
    },
    variables: {
      name: newSiteName,
      address: newSiteAddress,
      timeZone: newSiteTimeZone,
      file: newSiteLogo
    }
  });

  return (
    <div className='configure islandPage'>
      <Island>
        {loading && <div className='loading'><Spinner /></div>}
        {(!loading && sites.length === 0) && <div className='createSite'>
          <div className='createSiteForm'>
            <h1>Add a site</h1>
            <div className='form'>
              <InputWithLabel label='Site Name' type='text' value={newSiteName} onChange={event => setNewSiteName(event.target.value)} />
              <InputWithLabel label='Address' type='text' value={newSiteAddress} onChange={event => setNewSiteAddress(event.target.value)} />
              <select value={newSiteTimeZone} onChange={event => setNewSiteTimeZone(event.target.value)}>
                <option value='' disabled>Time Zone</option>
                {TIME_ZONES.map(timeZone => <option key={timeZone} value={timeZone}>UTC {getUTCHourOffset(timeZone) >= 0 ? '+' : ''}{getUTCHourOffset(timeZone)} ({timeZone.replace('_', ' ')})</option>)}
              </select>
              <FileUpload file={newSiteLogo} onChange={file => setNewSiteLogo(file)} buttonText='Choose Logo' />
              <div className='createButton'><Button size='large' text={creating ? 'Creating...' : 'Create'} onClick={creating ? null : createSite} /></div>
            </div>
          </div>
        </div>}
        {(!loading && sites.length > 0) && <div className='sitesList'>
          <h1>Sites</h1>
          {sites.map(site => <Link className='site' key={site.id} to={`/configure/${site.id}`}>{site.name}</Link>)}
        </div>}
      </Island>
    </div>
  );
};

const GET_SITES = gql`
  {
    sites {
      id
      name
    }
  }
`;

const CREATE_SITE = gql`
  mutation createSite($name: String!, $address: String!, $timeZone: String!, $file: Upload!) {
    createSite( name: $name, address: $address, timeZone: $timeZone, file: $file) { id }
  }
`;

export default Configure;
