import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'scss/ConfigureFrame.scss';

import Button from 'components/Button';
import Image from 'components/Image';
import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip';
import VAModules from 'components/VAModules';

import { deepCopy } from 'utilities';
import cloneIcon from 'images/clone.svg';
import hiddenIcon from 'images/hidden.svg';
import reverseCrossingIcon from 'images/reverseCrossing.png';
import visibleIcon from 'images/visible.svg';

const propTypes = {
  image: PropTypes.shape({
    data: PropTypes.string,
    resolution: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number
    })
  }),
  vaModules: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    zone: PropTypes.object,
    inZone: PropTypes.object,
    outZone: PropTypes.object
  })),
  cameraId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const ConfigureFrame = props => {
  const { width, height } = props.image.resolution;
  const [vaModules, setVAModules] = useState(props.vaModules);
  const [hiddenVAModuleIndices, setHiddenVAModuleIndices] = useState([]);
  const [deletedVAModuleIds, setDeletedVAModuleIds] = useState([]);
  const [showGuideModal, setShowGuideModal] = useState(false);
  const presenceTiming = useRef(null);

  const updateVAModule = (index, prop, value) => {
    const newVAModules = deepCopy(vaModules);
    newVAModules[index][prop] = value;
    setVAModules(newVAModules);
  };

  const updateVAModuleType = (index, value) => {
    const newVAModules = deepCopy(vaModules);
    if (value.startsWith('Presence')) {
      newVAModules[index].type = 'Presence';
      newVAModules[index].params = value === 'Presence-Dwell' ? { dwellFilter: 120 } : { timeThreshold: 300 };
      if (presenceTiming.current) presenceTiming.current.innerText = value === 'Presence-Dwell' ? '120' : '300';
    } else {
      newVAModules[index].type = value;
    }
    setVAModules(newVAModules);
  };

  const updatePresenceTiming = (index, value) => {
    if (!value) return;
    if (!vaModules[index].params) return;
    const newVAModules = deepCopy(vaModules);
    if (newVAModules[index].params.dwellFilter) newVAModules.params = { dwellFilter: parseInt(value, 10) };
    else if (newVAModules[index].params.timeThreshold) newVAModules.params = { timeThreshold: parseInt(value, 10) };
    setVAModules(newVAModules);
  };

  const reverseCrossing = index => {
    const newVAModules = deepCopy(vaModules);
    const outZone = newVAModules[index].inZone;
    newVAModules[index].inZone = newVAModules[index].outZone;
    newVAModules[index].outZone = outZone;
    setVAModules(newVAModules);
  };

  const deleteVAModules = indices => {
    const newDeletedVAModuleIds = [];
    const newVAModules = vaModules.filter((vaModule, index) => {
      if (!indices.includes(index)) return true;
      if (vaModule.id) newDeletedVAModuleIds.push(vaModule.id);
      return false;
    });
    if (newDeletedVAModuleIds.length > 0) setDeletedVAModuleIds([...deletedVAModuleIds, ...newDeletedVAModuleIds]);
    setVAModules(newVAModules);
    setHiddenVAModuleIndices([]);
  };

  const toggleHidden = index => {
    const newHiddenVAModuleIndices = hiddenVAModuleIndices.slice();
    const i = newHiddenVAModuleIndices.indexOf(index);
    if (i !== -1) newHiddenVAModuleIndices.splice(i, 1);
    else newHiddenVAModuleIndices.push(index);
    setHiddenVAModuleIndices(newHiddenVAModuleIndices);
  };

  const onClone = index => {
    const newVAModules = vaModules.slice();
    const newVAModule = deepCopy(vaModules[index]);
    delete newVAModule.id;
    newVAModule.name += ' Copy';
    newVAModules.push(newVAModule);
    setVAModules(newVAModules);
  };

  const onContentEditableKeyDown = event => {
    event.stopPropagation();
    if (event.key !== 'Enter') return;
    event.preventDefault();
    event.target.blur();
  };

  const containerStyle = {};
  const windowWidth = window.innerWidth - 300 - 24; // subtract 300 for sidebar and 24 for left padding
  const windowHeight = window.innerHeight - 24 - 24; // subtract 24 for top padding at 24 for bottom padding
  if (width / height > windowWidth / windowHeight) {
    containerStyle.width = windowWidth;
    containerStyle.height = (height / width) * windowWidth;
  } else {
    containerStyle.height = windowHeight;
    containerStyle.width = (width / height) * windowHeight;
  }

  return (
    <div className='configureFrame'>
      <div className='image'>
        <div className='imageContainer' style={containerStyle}>
          <Image src={`data:image/jpeg;base64, ${props.image.data}`} alt='camera image frame' />
          <VAModules
            vaModules={vaModules}
            cameraId={props.cameraId}
            resolution={props.image.resolution}
            hiddenVAModuleIndices={hiddenVAModuleIndices}
            onUpdate={setVAModules}
            onDelete={deleteVAModules} />
        </div>
      </div>
      <div className='tools'>
        <div className='vaModuleListIsland frameIsland'>
          {vaModules.length === 0 && <div className='empty'>Start drawing to create a va module.</div>}
          {vaModules.map((vaModule, index) => (
            <div className='vaModuleListItem' key={vaModule.id || index}>
              <div className='vaModuleListItemTop'>
                <div
                  className='vaModuleName'
                  suppressContentEditableWarning={true}
                  contentEditable={true}
                  onKeyDown={onContentEditableKeyDown}
                  onBlur={event => {
                    updateVAModule(index, 'name', event.target.innerText);
                    event.target.scrollLeft = 0;
                  }}>
                  {vaModule.name}
                </div>
                <div className='delete' onClick={() => deleteVAModules([index])}>&times;</div>
              </div>
              <div className='vaModuleType'>
                <select value={vaModule.type + (vaModule.params && vaModule.params.timeThreshold ? '-Loiter' : '') + (vaModule.params && vaModule.params.dwellFilter ? '-Dwell' : '')} onChange={event => updateVAModuleType(index, event.target.value)}>
                  <option value='' disabled={true}>Type</option>
                  <option value='Counting'>Counting</option>
                  <option value='Presence-Dwell'>Presence (Dwell)</option>
                  <option value='Presence-Loiter'>Presence (Loiter)</option>
                  <option value='EntryExit'>Entry/Exit</option>
                  <option value='Crossing' disabled={!vaModule.inZone || !vaModule.outZone}>Crossing</option>
                  <option value='AttributeChange'>AttributeChange</option>
                </select>
                <Tooltip onClick={() => setShowGuideModal(true)} />
              </div>
              <div className='vaModuleActions'>
                <Image className='toggleHidden' src={hiddenVAModuleIndices.includes(index) ? hiddenIcon : visibleIcon} alt='Toggle Visibility' onClick={() => toggleHidden(index)} />
                <Image className='clone' src={cloneIcon} alt='Clone' onClick={() => onClone(index)} />
                {vaModule.type === 'Crossing' && <Image className='reverseCrossing' src={reverseCrossingIcon} alt='Reverse crossing' onClick={() => reverseCrossing(index)} />}
                {vaModule.type === 'Presence' && (
                  <div className='inputContainer'>
                    <div
                      className='presenceTiming'
                      suppressContentEditableWarning={true}
                      contentEditable={true}
                      onKeyDown={onContentEditableKeyDown}
                      ref={presenceTiming}
                      onBlur={event => {
                        updatePresenceTiming(index, event.target.innerText);
                        event.target.scrollLeft = 0;
                      }}>
                      {vaModule.params && (vaModule.params.dwellFilter || vaModule.params.timeThreshold) ? (vaModule.params.dwellFilter || vaModule.params.timeThreshold) : 120}
                    </div>
                    <div className='suffix'>secs</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className='doneIsland frameIsland'>
          <Button text='Cancel' onClick={props.onCancel} />
          <Button text='Save' onClick={() => props.onSave(deepCopy(vaModules), deletedVAModuleIds.slice())} />
        </div>
      </div>
      {showGuideModal && (
        <Modal title='VA Module Guide' onClose={() => setShowGuideModal(false)}>
          <div><strong>Counting</strong> - Generates an event each frame with a count of how many objects are in the zone.</div>
          <div><strong>Presence (Dwell)</strong> - Generates an event when an object lingers in the zone with the amount of time stayed.</div>
          <div><strong>Presence (Loiter)</strong> - Generates an event when an object lingers in the zone for the configured time.</div>
          <div><strong>Entry/Exit</strong> - Generates an event when an object enters or exits the zone.</div>
          <div><strong>Crossing</strong> - Generates an event when an object crosses from the out zone (red) to the in zone (green).</div>
          <div><strong>AttributeChange</strong> - Generates an event when an an object in the zone changes status between active/inactive.</div>
        </Modal>
      )}
    </div>
  );
};

ConfigureFrame.propTypes = propTypes;
export default ConfigureFrame;
