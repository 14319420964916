import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import DatePicker from 'react-datepicker';
import gql from 'graphql-tag';
import 'react-datepicker/dist/react-datepicker.css';
import 'scss/DatePicker.scss';
import 'scss/HeatMap.scss';

import Image from 'components/Image';
import Spinner from 'components/Spinner';
import Tooltip from 'components/Tooltip';

const COLORS = ['#c0fff0', '#c0ffd6', '#aaffb6', '#b3ff87', '#dbff87', '#f9ff87', '#fff38d', '#ffe78d', '#ffcc80', '#ffb975', '#ff9679', '#ff8079', '#ff727a', '#ff5f6b'];
const MAX_PERCENT = 1;

const propTypes = {
  id: PropTypes.string.isRequired
};

const HeatMap = props => {
  const [timestamp, setTimestamp] = useState(new Date());
  const [timeScale, setTimeScale] = useState('month');
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const heatMapContainer = useRef(null);

  const { loading, data: { heatMap } = {} } = useQuery(GET_HEAT_MAP, {
    variables: {
      id: props.id,
      timeScale,
      timestamp: timestamp.getTime() / 1000
    }
  });

  const resizeImage = useCallback(() => {
    if (!heatMapContainer || !heatMapContainer.current) return;

    const imageWidth = heatMap ? heatMap.floorPlan.resolution.width : 1;
    const imageHeight = heatMap ? heatMap.floorPlan.resolution.height : 1;
    const heatMapContainerWidth = heatMapContainer.current.clientWidth;
    const heatMapContainerHeight = heatMapContainer.current.clientHeight;
    // set image size to "contain" within container aspect ratio, if container aspect ratio is wider constrain width and vice versa
    if (heatMapContainerWidth / heatMapContainerHeight > imageWidth / imageHeight) { // wider aspect ratio
      setImageSize({ width: heatMapContainerHeight * (imageWidth / imageHeight), height: heatMapContainerHeight });
    } else { // taller aspect ratio
      setImageSize({ width: heatMapContainerWidth, height: heatMapContainerWidth * (imageHeight / imageWidth) });
    }
  }, [heatMapContainer, heatMap, setImageSize]);

  useEffect(resizeImage, [heatMap]);

  useEffect(() => {
    window.addEventListener('resize', resizeImage);
    return () => window.removeEventListener('resize', resizeImage);
  }, [resizeImage]);

  const valueToRoundedPercent = value => Math.round(value * 10000) / 100; // rounding to hundredth place

  const onSeekTime = direction => { // forward or back
    const multiplier = direction === 'forward' ? 1 : -1;
    if (timeScale === 'hour') return setTimestamp(new Date(timestamp.getTime() + ((60 * 60 * 1000) * multiplier)));
    else if (timeScale === 'day') return setTimestamp(new Date(timestamp.getTime() + ((24 * 60 * 60 * 1000) * multiplier)));
    else if (timeScale === 'week') return setTimestamp(new Date(timestamp.getTime() + ((7 * 24 * 60 * 60 * 1000) * multiplier)));
    else if (timeScale === 'month') return setTimestamp(new Date(timestamp.getFullYear(), timestamp.getMonth() + multiplier, timestamp.getDate()));
    else if (timeScale === 'quarter') return setTimestamp(new Date(timestamp.getFullYear(), timestamp.getMonth() + (3 * multiplier), timestamp.getDate()));
  };

  const getColor = value => {
    const percent = Math.max(0, Math.min(1, valueToRoundedPercent(value) / MAX_PERCENT));
    const colorIndex = Math.ceil(percent * COLORS.length) - 1;
    return colorIndex === -1 ? 'transparent' : COLORS[colorIndex];
  };

  const filters = (
    <div className='filters'>
      <div className='arrow prevInterval' onClick={() => onSeekTime('back')}>&lsaquo;</div>
      <DatePicker
        className={timeScale !== 'hour' ? 'small' : ''}
        selected={timestamp}
        showTimeSelect={timeScale === 'hour'}
        timeIntervals={60}
        dateFormat={timeScale === 'hour' ? 'MM/dd/yy haa' : 'MM/dd/yyyy'}
        disabledKeyboardNavigation={true}
        onChange={time => setTimestamp(time)} />
      <select value={timeScale} onChange={event => setTimeScale(event.target.value)}>
        <option value='hour'>Hourly</option>
        <option value='day'>Daily</option>
        <option value='week'>Weekly</option>
        <option value='month'>Monthly</option>
        <option value='quarter'>Quarterly</option>
      </select>
      <div className='arrow nextInterval' onClick={() => onSeekTime('forward')}>&rsaquo;</div>
    </div>
  );

  if (loading) {
    return (
      <div className='heatMap'>
        <div className='heatMapTop'>
          <div className='titleContainer'><h1>Loading...</h1></div>
          {filters}
        </div>
        <div className='heatMapContainer loading' ref={heatMapContainer}><Spinner /></div>
      </div>
    );
  }

  return (
    <div className='heatMap'>
      <div className='heatMapTop'>
        <div className='titleContainer'>
          <h1>{heatMap.title}</h1>
          <Tooltip>{heatMap.description}</Tooltip>
        </div>
        {filters}
      </div>
      <div className='heatMapContainer' ref={heatMapContainer}>
        <div className='imageContainer' style={imageSize}>
          <Image src={`data:image/jpeg;base64, ${heatMap.floorPlan.data}`} alt='floor plan' />
          <div className='heatMapData'>
            {heatMap.data.map((row, rowIdx) => (
              <div className='heatMapDataRow' key={`row${rowIdx}`}>
                {row.map((data, dataIdx) => (
                  <div className='heatMapDataCell' key={`cell${dataIdx}`}>
                    <div className='heatMapDataCellColor' style={{ backgroundColor: getColor(data) }} />
                    {valueToRoundedPercent(data) > 0 && <div className='heatMapDataCellTooltip'>
                      <div className='color' style={{ backgroundColor: getColor(data) }} />
                      <div className='value'>{`${valueToRoundedPercent(data)}%`}</div>
                    </div>}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className='legend'>
            <div className='label'>Percent Time Occupied</div>
            <div className='colorsIndicator' style={{ background: `linear-gradient(to top,${COLORS.join(',')})` }} />
            <div className='valuesIndicator'>
              <div>{`${MAX_PERCENT}%`}</div>
              <div>0</div>
            </div>
          </div>
        </div>
      </div>
      <div className='sources'>{heatMap.sources.length === 1 ? 'source' : 'sources'}: {heatMap.sources.join(', ')}</div>
    </div>
  );
};

const GET_HEAT_MAP = gql`
  query heatMap($id: ID!, $timeScale: String!, $timestamp: Float!) {
    heatMap(id: $id) {
      id
      title
      description
      sources
      floorPlan {
        data
        resolution {
          width
          height
        }
      }
      data(timeScale: $timeScale, timestamp: $timestamp)
    }
  }
`;

HeatMap.propTypes = propTypes;
export default HeatMap;
