import { useState, useEffect } from 'react';

const useKeyHeld = key => {
  const [isHeld, setIsHeld] = useState(false);

  const onKeyDown = event => {
    if (event.key === key) setIsHeld(true);
  };

  const onKeyUp = event => {
    if (event.key === key) setIsHeld(false);
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    // unmount
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  return isHeld;
};

export default useKeyHeld;
