import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AccessTokenContext } from 'context/AccessToken';

// If signed in, will redirect user to /
const LoggedOutRoute = ({ component: Component, ...otherProps }) => {
  const { accessToken } = useContext(AccessTokenContext);

  return (
    <Route
      {...otherProps}
      render={props => accessToken ? <Redirect to='/' /> : <Component {...props} />} />
  );
};

export default LoggedOutRoute;
