import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import 'scss/Sidebar.scss';

import Image from 'components/Image';

const Sidebar = props => {
  const { loading, data: { site } = {} } = useQuery(GET_SITE);
  const activePath = props.location.pathname.substr(1);

  if (!site) return <div className='sidebar collapsed' />;

  return (
    <div className='sidebar'>
      <div className='logoContainer'>
        {site && <Image src={`data:image/png;base64, ${site.logo.data}`} alt='logo' />}
      </div>
      {loading && <div className='loading'>loading...</div>}
      {!loading && <Link className={activePath === '' ? 'active' : ''} key='' to='/'>Dashboard</Link>}
      {site && site.heatMaps.map(heatMap => <Link className={activePath === `heatmap/${heatMap.id}` ? 'active' : ''} key={heatMap.id} to={`/heatmap/${heatMap.id}`}>{heatMap.title}</Link>)}
      {site && site.graphs.map(graph => <Link className={activePath === `graph/${graph.id}` ? 'active' : ''} key={graph.id} to={`/graph/${graph.id}`}>{graph.title}</Link>)}
    </div>
  );
};

const GET_SITE = gql`
  {
    site {
      id
      name
      address
      graphs {
        id
        title
      }
      heatMaps {
        id
        title
      }
      logo {
        data
      }
    }
  }
`;

export default withRouter(Sidebar);
