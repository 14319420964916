import React, { createContext, useState } from 'react';

const AccessTokenContext = createContext({
  accessToken: '',
  setAccessToken: accessToken => {}
});

const AccessTokenProvider = props => {
  const [accessToken, setAccessToken] = useState('');

  return (
    <AccessTokenContext.Provider
      value={{ accessToken, setAccessToken }}
      {...props} />
  );
};

export { AccessTokenContext, AccessTokenProvider };
