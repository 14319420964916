import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import 'scss/Header.scss';

import { AccessTokenContext } from 'context/AccessToken';
import Image from 'components/Image';

import syntiantLogo from 'images/syntiant.svg';

const Header = () => {
  const { accessToken, setAccessToken } = useContext(AccessTokenContext);

  const [onLogout] = useMutation(LOGOUT_USER, {
    update: async () => {
      setAccessToken('');
    }
  });

  return (
    <div className='header'>
      <div className='poweredBy'>
        <div>powered by&nbsp;</div>
        <a href='https://syntiant.com/' target='_blank' rel="noreferrer">
          <Image src={syntiantLogo} alt='Syntiant' />
        </a>
      </div>
      <div className='userInfo'>
        {accessToken && <div className='fakeLink' onClick={onLogout}>Sign Out</div>}
      </div>
    </div>
  );
};

const LOGOUT_USER = gql`
  mutation logout {
    logout
  }
`;

export default Header;
