import React from 'react';
import PropTypes from 'prop-types';
import 'scss/FileUpload.scss';

const propTypes = {
  file: PropTypes.object,
  buttonText: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

const FileUpload = props => {
  return (
    <div className='fileUpload'>
      <input
        type='file'
        name='file'
        id='file'
        className='fileInput'
        onChange={event => props.onChange(event.target.files[0])}
        multiple={false}
        capture />
      <label className='uploadButton' htmlFor='file'>{props.buttonText || 'Choose File'}</label>
      <label className='uploadText' htmlFor='file'>{!props.file ? 'Choose File(s)' : props.file.name}</label>
    </div>
  );
};

FileUpload.propTypes = propTypes;
export default FileUpload;
