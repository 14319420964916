import React from 'react';
import PropTypes from 'prop-types';
import 'scss/Island.scss';

const propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOf(['light', 'dark']),
  hidden: PropTypes.bool
};

const defaultProps = {
  style: 'light'
};

const Island = props => {
  return (
    <div className={'island' + (props.className ? ` ${props.className}` : '') + (props.style === 'dark' ? ' dark' : '') + (props.hidden ? ' hidden' : '')}>
      {props.children}
    </div>
  );
};

Island.propTypes = propTypes;
Island.defaultProps = defaultProps;
export default Island;
