import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { ApolloClient, ApolloProvider, from, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from 'apollo-link-context';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import 'scss/App.scss';

import { AccessTokenContext } from 'context/AccessToken';

import Header from 'components/Header';
import LoggedInRoute from 'components/LoggedInRoute';
import LoggedOutRoute from 'components/LoggedOutRoute';
import Modal from 'components/Modal';
import Sidebar from 'components/Sidebar';

import Configure from 'pages/Configure';
import ConfigureCamera from 'pages/ConfigureCamera';
import ConfigureGraph from 'pages/ConfigureGraph';
import ConfigureHeatMap from 'pages/ConfigureHeatMap';
import ConfigureSite from 'pages/ConfigureSite';
import Dashboard from 'pages/Dashboard';
import GraphPage from 'pages/GraphPage';
import HeatMapPage from 'pages/HeatMapPage';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';

const App = () => {
  const { accessToken, setAccessToken } = useContext(AccessTokenContext);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await fetch('/refresh_token', { method: 'POST', credentials: 'include' });
      const { accessToken } = await response.json();
      setAccessToken(accessToken);
      setIsLoading(false);
    })();
  }, []);

  const httpLink = new createUploadLink({ uri: '/graphql' }); // http link points to graphql api

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const errors = [];

    if (networkError) console.error(`[Network Error]: ${networkError}`);

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message.includes('token')) return; // auth token error is not an actual error
        console.error(`[GraphQL Error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        errors.push(message);
      });
    }

    if (errors.length > 0) setErrors(errors);
  });

  const authLink = setContext(() => { // authlink includes auth headers in every request
    return {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : ''
      }
    };
  });

  const tokenRefreshLink = new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: () => {
      if (!accessToken) return true;
      try {
        const { exp } = jwtDecode(accessToken);
        if (Date.now() < exp * 1000) return true; // check if token is expired
        return false;
      } catch (error) {
        return false;
      }
    },
    fetchAccessToken: async () => fetch('/refresh_token', { method: 'POST', credentials: 'include' }),
    handleFetch: accessToken => setAccessToken(accessToken),
    handleError: error => {
      console.warn('Your refresh token is invalid. Try to log in again.');
      console.error(error);
    }
  });

  const client = new ApolloClient({
    link: from([tokenRefreshLink, authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
    credentials: 'include'
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter basename='/'>
        <div className='app'>
          {!isLoading && <Sidebar />}
          <div className='page'>
            <div className='colorStripe' />
            <div className='pageContent'>
              <Header />
              {!isLoading && <Switch>
                <LoggedOutRoute exact path='/login' component={Login} />
                <LoggedInRoute exact path='/' component={Dashboard} />
                <LoggedInRoute exact path='/graph/:id' component={GraphPage} />
                <LoggedInRoute exact path='/heatmap/:id' component={HeatMapPage} />
                <LoggedInRoute exact path='/configure' component={Configure} />
                <LoggedInRoute exact path='/configure/:id' component={ConfigureSite} />
                <LoggedInRoute exact path='/configure-camera/:id' component={ConfigureCamera} />
                <LoggedInRoute exact path='/configure-graph/:id' component={ConfigureGraph} />
                <LoggedInRoute exact path='/configure-heat-map/:id' component={ConfigureHeatMap} />
                <Route component={NotFound} />
              </Switch>}
            </div>
          </div>
          {errors && <Modal title='An Error Occurred' onClose={() => setErrors(null)}>{errors.map((message, index) => <div key={index}>{message}</div>)}</Modal>}
        </div>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
