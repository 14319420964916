export const timeToString = time => {
  const date = new Date(time * 1000);
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
  const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
  const hours = date.getHours() === 0 || date.getHours() === 12 ? '12' : `${date.getHours() % 12 < 10 ? '0' : ''}${date.getHours() % 12}`;
  const minutes = `${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
  const ampm = date.getHours() < 12  ? 'AM' : 'PM';
  return `${year}-${month}-${day} ${hours}:${minutes}${ampm}`;
};

export const deepCopy = item => {
  if (Array.isArray(item)) return item.map(subItem => deepCopy(subItem));
  if (item !== null && typeof item === 'object') {
    const newItem = Object.assign({}, item);
    if (newItem.__typename) delete newItem.__typename; // strip graphql auto populated fields
    Object.keys(newItem).forEach(key => {
      newItem[key] = deepCopy(newItem[key]);
    });
    return newItem;
  }
  return item;
};
