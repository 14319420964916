import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AccessTokenContext } from 'context/AccessToken';

// If not signed in, will redirect user to /login
const LoggedInRoute = ({ component: Component, ...otherProps }) => {
  const { accessToken } = useContext(AccessTokenContext);

  return (
    <Route
      {...otherProps}
      render={props => !accessToken ? <Redirect to='/login' /> : <Component {...props} />} />
  );
};

export default LoggedInRoute;
