import React from 'react';
import 'scss/IslandPage.scss';

import HeatMap from 'components/HeatMap';
import Island from 'components/Island';

const HeatMapPage = props => {
  const id = props.match.params.id;

  return (
    <div className='heatMapPage islandPage'>
      <Island>
        <HeatMap id={id} />
      </Island>
    </div>
  );
};

export default HeatMapPage;
