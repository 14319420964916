import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import 'scss/ConfigurePage.scss';
import 'scss/IslandPage.scss';

import Button from 'components/Button';
import FileUpload from 'components/FileUpload';
import ConfigureFrame from 'components/ConfigureFrame';
import InputWithLabel from 'components/InputWithLabel';
import Island from 'components/Island';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';

const ConfigureCamera = props => {
  const id = props.match.params.id;
  const [success, setSuccess] = useState(null);
  const [cameraName, setCameraName] = useState(null);
  const [cameraUrl, setCameraUrl] = useState(null);
  const [cameraIsFisheye, setCameraIsFisheye] = useState(null);
  const [cameraPreview, setCameraPreview] = useState(null);
  const [homography, setHomography] = useState(null);
  const [shouldShowFrame, setShouldShowFrame] = useState(false);

  const { loading, refetch, data: { camera } = {} } = useQuery(GET_CAMERA, { variables: { id } });

  const [upsertVAModules, { loading: upsertingVAModules }] = useMutation(UPSERT_VA_MODULES, {
    onCompleted: () => {
      refetch();
      setShouldShowFrame(false);
    }
  });

  const [updateCamera, { loading: updatingCamera }] = useMutation(UPDATE_CAMERA, {
    onCompleted: () => {
      refetch();
      setCameraName(null);
      setCameraUrl(null);
      setCameraIsFisheye(null);
      setCameraPreview(null);
      setSuccess('Camera updated successfully!');
    },
    variables: {
      id: camera ? camera.id : '',
      name: cameraName,
      url: cameraUrl,
      isFisheye: cameraIsFisheye,
      file: cameraPreview
    }
  });

  const [updateHomography, { loading: isUpdatingHomography }] = useMutation(UPDATE_HOMOGRAPHY, {
    onCompleted: () => {
      refetch();
      setSuccess('Successfully updated homography.');
    },
    variables: { cameraId: camera ? camera.id : '', homography }
  });

  return (
    <div className='configureCamera configurePage islandPage'>
      <Island>
        {loading && <div className='loading'><Spinner /></div>}
        {(!loading && camera) && <div className='configureContents'>
          <div className='details'>
            <div className='detailsTop'>
              <Link to={`/configure/${camera.siteId}`}>&lsaquo;</Link>
              <h1>Camera Details</h1>
            </div>
            <InputWithLabel label='Camera Name' value={cameraName === null ? camera.name : cameraName} onChange={event => setCameraName(event.target.value)} />
            <InputWithLabel label='Camera URL' value={cameraUrl === null ? camera.url : cameraUrl} onChange={event => setCameraUrl(event.target.value)} />
            <Toggle label='Is Fisheye' value={cameraIsFisheye === null ? camera.isFisheye : cameraIsFisheye} onToggle={() => setCameraIsFisheye(cameraIsFisheye === null ? !camera.isFisheye : !cameraIsFisheye)} />
            <FileUpload file={cameraPreview} buttonText='Change Frame' onChange={file => setCameraPreview(file)} />
            <Button text={updatingCamera ? 'Updating...' : 'Update Camera Details'} onClick={updateCamera} />
          </div>
          <div className='settings'>
            <h1>Camera Contents</h1>
            <div className='section'>
              <div className='sectionTitle'>VA Modules</div>
              {camera.vaModules.map(vaModule => <div key={vaModule.id} className='sectionRow vaModule'>
                <div className='name'>{vaModule.name}</div>
              </div>)}
              <div className='fakeLink' onClick={() => setShouldShowFrame(true)}>configure</div>
            </div>
            <div className='section'>
              <InputWithLabel label='Homography' value={homography === null ? camera.homography : homography} onChange={event => setHomography(event.target.value)} />
              <Button text={isUpdatingHomography ? 'Updating...' : 'Update Homography'} onClick={isUpdatingHomography ? () => {} : updateHomography} />
            </div>
          </div>
        </div>}
      </Island>
      {success && <Modal title='Success!' onClose={() => setSuccess(null)}>{success}</Modal>}
      {shouldShowFrame && (
        <ConfigureFrame
          image={camera.image}
          vaModules={camera.vaModules}
          cameraId={camera.id}
          onCancel={() => setShouldShowFrame(false)}
          onSave={upsertingVAModules ? () => {} : (vaModules, deletedVAModuleIds) => upsertVAModules({ variables: { vaModules, deletedIds: deletedVAModuleIds } })} />
      )}
    </div>
  );
};

const GET_CAMERA = gql`
  query camera($id: ID!) {
    camera(id: $id) {
      id
      siteId
      name
      url
      isFisheye
      homography
      image {
        data
        resolution {
          width
          height
        }
      }
      vaModules {
        id
        cameraId
        name
        type
        zone {
          includePolygons
          excludePolygons
        }
        inZone {
          includePolygons
          excludePolygons
        }
        outZone {
          includePolygons
          excludePolygons
        }
        params {
          dwellFilter
          timeThreshold
        }
      }
    }
  }
`;

const UPSERT_VA_MODULES = gql`
  mutation upsertVAModules($vaModules: [VAModuleInput]!, $deletedIds: [ID]!) {
    upsertVAModules(vaModules: $vaModules, deletedIds: $deletedIds)
  }
`;

const UPDATE_CAMERA = gql`
  mutation updateCamera($id: ID!, $name: String, $url: String, $isFisheye: Boolean, $file: Upload) {
    updateCamera(id: $id, name: $name, url: $url, isFisheye: $isFisheye, file: $file)
  }
`;

const UPDATE_HOMOGRAPHY = gql`
  mutation updateHomography($cameraId: ID!, $homography: String!) {
    updateHomography(cameraId: $cameraId, homography: $homography)
  }
`;


export default ConfigureCamera;
