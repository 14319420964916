import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import 'scss/ConfigurePage.scss';
import 'scss/IslandPage.scss';

import Button from 'components/Button';
import FileUpload from 'components/FileUpload';
import InputWithLabel from 'components/InputWithLabel';
import Island from 'components/Island';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';

const ConfigureGraph = props => {
  const id = props.match.params.id;

  const [success, setSuccess] = useState(null);
  const [graphTitle, setGraphTitle] = useState(null);
  const [graphDescription, setGraphDescription] = useState(null);
  const [graphYAxisLabel, setGraphYAxisLabel] = useState(null);
  const [graphAlgorithm, setGraphAlgorithm] = useState(null);
  const [graphObjectType, setGraphObjectType] = useState(null);
  const [graphImage, setGraphImage] = useState(null);

  const { loading, refetch, data: { graph, vaModules } = {} } = useQuery(GET_GRAPH, { variables: { id } });
  const [addVAModule, { loading: addingVAModule }] = useMutation(ADD_VA_MODULE, { onCompleted: refetch });
  const [removeVAModule, { loading: removingVAModule }] = useMutation(REMOVE_VA_MODULE, { onCompleted: refetch });
  const [addToDashboard, { loading: addingToDashboard }] = useMutation(ADD_TO_DASHBOARD, { onCompleted: refetch });
  const [removeFromDashboard, { loading: removingFromDashboard }] = useMutation(REMOVE_FROM_DASHBOARD, { onCompleted: refetch });
  const [updateGraph, { loading: updatingGraph }] = useMutation(UPDATE_GRAPH, {
    onCompleted: () => setSuccess('Successfully updated graph.'),
    variables: {
      id: graph ? graph.id : '',
      title: graphTitle,
      description: graphDescription,
      yAxisLabel: graphYAxisLabel,
      algorithm: graphAlgorithm,
      objectType: graphObjectType,
      file: graphImage
    }
  });

  const toggleDashboard = moduleType => {
    if (addingToDashboard || removingFromDashboard) return;
    if (graph.dashboardModules.includes(moduleType)) removeFromDashboard({ variables: { siteId: graph.siteId, graphId: graph.id, moduleType } });
    else addToDashboard({ variables: { siteId: graph.siteId, graphId: graph.id, moduleType } });
  };

  return (
    <div className='configureGraph configurePage islandPage'>
      <Island>
        {loading && <div className='loading'><Spinner /></div>}
        {(!loading && graph && vaModules) && <div className='configureContents'>
          <div className='details'>
            <div className='detailsTop'>
              <Link to={`/configure/${graph.siteId}`}>&lsaquo;</Link>
              <h1>Graph Details</h1>
            </div>
            <InputWithLabel label='Title' value={graphTitle || graph.title} onChange={event => setGraphTitle(event.target.value)} />
            <InputWithLabel label='Description' value={graphDescription || graph.description} onChange={event => setGraphDescription(event.target.value)} />
            <InputWithLabel label='Y Axis Label' value={graphYAxisLabel || graph.yAxisLabel} onChange={event => setGraphYAxisLabel(event.target.value)} />
            <select value={graphAlgorithm || graph.algorithm} onChange={event => setGraphAlgorithm(event.target.value)}>
              <option value='' disabled>Algorithm</option>
              <option value='eventCount'>Event Count</option>
              <option value='eventCountAverage'>Event Count Average</option>
              <option value='eventValueAverage'>Event Value Average</option>
              <option value='anyEvent'>Any Event</option>
            </select>
            <select value={graphObjectType || graph.objectType} onChange={event => setGraphObjectType(event.target.value)}>
              <option value='' disabled>Object Type</option>
              <option value='body'>Body</option>
              <option value='vehicle'>Vehicle</option>
              <option value='gas_pump_handle'>Gas Pump Handle</option>
            </select>
            <FileUpload file={graphImage} buttonText='Update Image' onChange={file => setGraphImage(file)} />
            <Button text={updatingGraph ? 'Updating...' : 'Update Graph Details'} onClick={updatingGraph ? () => {} : updateGraph} />
          </div>
          <div className='settings'>
            <h1>Graph Settings</h1>
            <div className='section'>
              <div className='sectionTitle'>Included VA Modules</div>
              {graph.vaModules.map(vaModule => <div className='sectionRow vaModule' key={vaModule.id}>
                <div className='name'>{vaModule.name}</div>
                <div className='delete' onClick={removingVAModule ? () => {} : () => removeVAModule({ variables: { graphId: graph.id, vaModuleId: vaModule.id } })}>&times;</div>
              </div>)}
              <select value='' onChange={addingVAModule ? () => {} : event => addVAModule({ variables: { graphId: graph.id, vaModuleId: event.target.value } })}>
                <option value='' disabled={true}>Add VA Module</option>
                {vaModules.map(vaModule => <option key={vaModule.id} value={vaModule.id}>{vaModule.name}</option>)}
              </select>
            </div>
            <div className='section'>
              <Toggle label='Show as dashboard count graph' value={graph.dashboardModules.includes('count')} onToggle={() => toggleDashboard('count')} />
              <Toggle label='Show as dashboard popular times graph' value={graph.dashboardModules.includes('popularTimes')} onToggle={() => toggleDashboard('popularTimes')} />
            </div>
          </div>
        </div>}
      </Island>
      {success && <Modal title='Success!' onClose={() => setSuccess(null)}>{success}</Modal>}
    </div>
  );
};

const GET_GRAPH = gql`
  query graph($id: ID!) {
    graph(id: $id) {
      id
      algorithm
      objectType
      siteId
      title
      description
      yAxisLabel
      dashboardModules
      vaModules {
        id
        name
      }
    }

    vaModules {
      id
      name
    }
  }
`;

const UPDATE_GRAPH = gql`
  mutation updateGraph($id: ID!, $title: String, $description: String, $yAxisLabel: String, $algorithm: String, $objectType: String, $file: Upload) {
    updateGraph(id: $id, title: $title, description: $description, yAxisLabel: $yAxisLabel, algorithm: $algorithm, objectType: $objectType, file: $file)
  }
`;

const ADD_VA_MODULE = gql`
  mutation addVAModuleToGraph($graphId: ID!, $vaModuleId: ID!) {
    addVAModuleToGraph(graphId: $graphId, vaModuleId: $vaModuleId)
  }
`;

const REMOVE_VA_MODULE = gql`
  mutation removeVAModuleFromGraph($graphId: ID!, $vaModuleId: ID!) {
    removeVAModuleFromGraph(graphId: $graphId, vaModuleId: $vaModuleId)
  }
`;

const ADD_TO_DASHBOARD = gql`
  mutation addGraphToDashboard($siteId: ID!, $graphId: ID!, $moduleType: String!) {
    addGraphToDashboard(siteId: $siteId, graphId: $graphId, moduleType: $moduleType)
  }
`;

const REMOVE_FROM_DASHBOARD = gql`
  mutation removeGraphFromDashboard($siteId: ID!, $graphId: ID!, $moduleType: String!) {
    removeGraphFromDashboard(siteId: $siteId, graphId: $graphId, moduleType: $moduleType)
  }
`;

export default ConfigureGraph;
