import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import 'scss/Dashboard.scss';

import Event from 'components/Event';
import Spinner from 'components/Spinner';
import Tooltip from 'components/Tooltip';

const Dashboard = () => {
  const { loading, data: { site } = {} } = useQuery(GET_SITE);

  if (!loading && !site) return <Redirect to='/configure' />;

  const renderCamera = camera => {
    return (
      <div className='camera' key={camera.id}>
        <div className={'cameraStatus' + (camera.status ? ' online' : ' offline')} />
        <div className='cameraName'>{camera.name}</div>
      </div>
    );
  };

  const renderCountStatistic = statistic => {
    return (
      <div className='statistic' key={statistic.label}>
        <h1 className='statisticTitle'>{statistic.value} {statistic.label}</h1>
        <div className='statisticSubtitle'>last 24 hours</div>
        <div className='statisticDataContainer'>
          <div className='statisticData'>
            <div className='statisticDataValue' style={{ color: statistic.value >= statistic.previousValue ? '#92cf51' : '#f74569' }}>
              {statistic.value >= statistic.previousValue ? '+' : ''}
              {statistic.value - statistic.previousValue}
              {' '}
              ({statistic.previousValue === 0 ? 0 : Math.abs(Math.round(((statistic.value - statistic.previousValue) / statistic.previousValue) * 100))}%)
            </div>
            <div className='statisticDataLabel'>since previous 24 hours</div>
          </div>
        </div>
      </div>
    );
  };

  const renderAverageGraph = graph => {
    const graphData = graph.data.map(({ x, y }) => ({ hour: new Date(x * 1000).getHours(), value: y }));
    graphData.sort((a, b) => a.hour - b.hour);
    let maxValue = graphData.length > 0 ? graphData[0].value : 0;
    let minValue = graphData.length > 0 ? graphData[0].value : 0;
    graphData.forEach(({ value }) => {
      if (value > maxValue) maxValue = value;
      if (value < minValue) minValue = value;
    });

    return (
      <div className='averageGraph' key={graph.title}>
        <div className='averageGraphTop'>
          <h1>{graph.title}</h1>
          <Tooltip>Graph is generated from last week of daily data.</Tooltip>
        </div>
        <div className='barGraph'>
          {graphData.map(({ hour, value }, index) => (
            <div className='barContainer' key={index}>
              <div className='bar' style={{ height: `${Math.round(((value - minValue) / (maxValue - minValue)) * 100)}px` }} />
              <div className='barLabel'>{hour === 0 || hour === 12 ? 12 : hour % 12}{hour < 12 ? 'am' : 'pm'}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className='dashboard'>
      {loading && <div className='spinnerContainer'><Spinner /></div>}
      {(site && site.dashboard) &&
      <div className='dashboardContent'>
        <div className='data'>
          <div className='welcome'>
            <h1>Welcome back</h1>
            <div className='welcomeSubtitle'>Showing data for {site.name}</div>
          </div>
          <div className='statistics'>
            {site.dashboard.countStatistics.map(statistic => renderCountStatistic(statistic))}
          </div>
          <div className='averageGraphs'>
            {site.dashboard.averageGraphs.map(averageGraph => renderAverageGraph(averageGraph))}
          </div>
        </div>
        <div className='rightColumn'>
          <div className='events'>
            <h1>Events</h1>
            {site.dashboard.events.length === 0 && <div className='noEvents'>No recent events</div>}
            {site.dashboard.events.length > 0 && site.dashboard.events.map(event => <Event key={event.id} type={event.type} timestamp={event.timestamp} output={event.output} />)}
          </div>
          <div className='status'>
            <h1>Camera Status</h1>
            <div className='cameras'>{site.dashboard.cameras.map(source => renderCamera(source))}</div>
          </div>
        </div>
      </div>}
    </div>
  );
};

const GET_SITE = gql`
  query site {
    site {
      id
      name
      dashboard {
        cameras {
          id
          name
          status
        }
        events {
          id
          timestamp
          type
          output {
            name
            value
          }
        }
        countStatistics {
          label
          value
          previousValue
        }
        averageGraphs {
          title
          data {
            x
            y
          }
        }
      }
    }
  }
`;

export default Dashboard;
